"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypCollection = void 0;
var utils_1 = require("../utils/utils");
/**
 * A collection in a Hypertable project.
 *
 * Properties of the collection can be read, and methods to interacting with records
 * within the collection are provided.
 */
var HypCollection = /** @class */ (function () {
    /** @internal */
    function HypCollection(lib, initial) {
        this.lib = lib;
        this.id = initial.id;
        this.name = initial.name;
        this.description = initial.description;
        this.titleFieldId = initial.titleFieldId;
        this.fields = utils_1.HypClientLibUtils.dereference(initial.fields);
    }
    Object.defineProperty(HypCollection.prototype, "titleField", {
        /**
         * The field definition of the "title" field.
         * The "title" field can be used to find a user-friendly "name" for each record in the collection.
         *
         * Example:
         * ```ts
         * const title = myRecord.readField(myCollection.titleField);
         * ```
         */
        get: function () {
            var _this = this;
            var field = this.fields.find(function (f) { return f.id === _this.titleFieldId; });
            if (!field)
                throw new Error("The title field could not be found (ID: " + this.titleFieldId + ")");
            return field;
        },
        enumerable: false,
        configurable: true
    });
    ;
    /**
     * Read the field with the given name
     * @param fieldName The name of the desired field
     * @returns The desired field, or undefined if the field was not found
     */
    HypCollection.prototype.getField = function (fieldName) {
        return this.fields.find(function (f) { return f.name === fieldName; });
    };
    /**
     * Read the field with the given ID
     * @param fieldId The ID of the desired field
     * @returns The desired field, or undefined if the field was not found
     */
    HypCollection.prototype.getFieldById = function (fieldId) {
        return this.fields.find(function (f) { return f.id === fieldId; });
    };
    /**
     * Read the field with the given key
     * @param fieldKey The key of the desired field
     * @returns The desired field, or undefined if the field was not found
     */
    HypCollection.prototype.getFieldByKey = function (fieldKey) {
        return this.fields.find(function (f) { return f.key === fieldKey; });
    };
    /**
     * Read the record with the given ID
     * @param id The ID of the desired record
     * @returns The desired record
     * @throws Throws an error if the record was not found
     */
    HypCollection.prototype.getRecord = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.lib.getRecord(this.id, id)];
            });
        });
    };
    /**
     * Find the record with the given ID
     * @param id The ID of the desired record
     * @returns The desired record, or undefined if the record was not found
     */
    HypCollection.prototype.findRecord = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.lib.findRecord(this.id, id)];
            });
        });
    };
    /**
     * Find a number of records by <what?>
     * @param how ?
     */
    HypCollection.prototype.findRecords = function (how) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("TODO: HypCollection.findRecords()");
            });
        });
    };
    /**
     * Get All records from this collection
     * (May only return a page in some circumstances)
     * @returns All the records in the collection
     */
    HypCollection.prototype.getAllRecords = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.lib.getAllRecords(this.id)];
            });
        });
    };
    /**
     * Create and return a new empty record in the collection.
     * @returns The created record, or undefined if the operation was not successful
     */
    HypCollection.prototype.createRecord = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.lib.createRecord(this.id)];
            });
        });
    };
    /**
     * Delete all reords in the collection
     *
     * TODO! Not implemented
     */
    HypCollection.prototype.deleteAllRecords = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("Not implemented: HypCollection.deleteAllRecords()");
            });
        });
    };
    return HypCollection;
}());
exports.HypCollection = HypCollection;
