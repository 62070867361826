"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypClientLibFunctionExecManager = void 0;
var hyp_collection_1 = require("../data/hyp-collection");
var hyp_record_1 = require("../data/hyp-record");
/**
 * Class containing functions to execute custom Javascript code defined by the user.
 * This includes formula fields, custom functions, and remotely-defined custom functions.
 *
 * @internal
 */
var HypClientLibFunctionExecManager = /** @class */ (function () {
    function HypClientLibFunctionExecManager(hypClientLib, testModeClientLib) {
        this.hypClientLib = hypClientLib;
        this.testModeClientLib = testModeClientLib;
    }
    HypClientLibFunctionExecManager.prototype.clientRecordAsFormulaRecord = function (record) {
        var r = {
            id: record.id,
            createdAtTs: record.createdAt,
            updatedAtTs: record.updatedAt,
            createdAt: function () { return new Date(record.createdAt); },
            updatedAt: function () { return new Date(record.updatedAt); },
            createdById: record.createdBy,
            updatedById: record.updatedBy,
            createdBy: function () {
                return {
                    id: record.createdBy,
                    name: "<User Name>",
                    colour: "#007ba7",
                    image: "",
                };
            },
            updatedBy: function () {
                return {
                    id: record.updatedBy,
                    name: "<User Name>",
                    colour: "#007ba7",
                    image: "",
                };
            },
            field: function (fieldId) {
                return record.fieldValues[fieldId];
            },
        };
        return r;
    };
    HypClientLibFunctionExecManager.prototype.runFieldFormula = function (functionBody, record) {
        return __awaiter(this, void 0, void 0, function () {
            var r, fieldFunc, result;
            return __generator(this, function (_a) {
                r = this.clientRecordAsFormulaRecord(record);
                try {
                    fieldFunc = new Function("r", functionBody);
                    result = fieldFunc(r);
                    // console.log(`[Func Exec] Run field formula on record: ${record.id}, result: ${result}`);
                    return [2 /*return*/, { result: result }];
                }
                catch (error) {
                    return [2 /*return*/, {
                            result: "",
                            error: (error instanceof Error) ? error.message : String(error),
                        }];
                }
                return [2 /*return*/];
            });
        });
    };
    HypClientLibFunctionExecManager.prototype.runFieldFormulaMany = function (functionBody, records) {
        return __awaiter(this, void 0, void 0, function () {
            var rList, results, i, fieldFunc, r, result;
            var _this = this;
            return __generator(this, function (_a) {
                rList = records.map(function (record) { return _this.clientRecordAsFormulaRecord(record); });
                try {
                    results = [];
                    for (i = 0; i < rList.length; i++) {
                        fieldFunc = new Function("r", functionBody);
                        r = rList[i];
                        result = fieldFunc(r);
                        results[i] = result;
                        // console.log(`[Func Exec] Run field formula on record: ${record.id}, result: ${result}`);
                    }
                    return [2 /*return*/, { results: results }];
                }
                catch (error) {
                    return [2 /*return*/, {
                            results: [],
                            error: (error instanceof Error) ? error.message : String(error),
                        }];
                }
                return [2 /*return*/];
            });
        });
    };
    HypClientLibFunctionExecManager.prototype.runCustomFunction = function (functionBody, opts) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var testMode, clientLibInstance, context, params, paramNames, pseudoAsyncBody, customFunction, result, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        testMode = opts.test === true;
                        clientLibInstance = testMode ? this.testModeClientLib : this.hypClientLib;
                        context = {
                            record: ((_a = opts === null || opts === void 0 ? void 0 : opts.context) === null || _a === void 0 ? void 0 : _a.record) ? new hyp_record_1.HypRecord(clientLibInstance, opts.context.record) : undefined,
                            collection: ((_b = opts === null || opts === void 0 ? void 0 : opts.context) === null || _b === void 0 ? void 0 : _b.collection) ? new hyp_collection_1.HypCollection(clientLibInstance, opts.context.collection) : undefined,
                            // view
                        };
                        params = [
                            { paramName: "H", paramValue: clientLibInstance },
                            { paramName: "context", paramValue: context },
                            { paramName: "ctx", paramValue: context },
                            { paramName: "parameters", paramValue: [] },
                            { paramName: "params", paramValue: [] },
                        ];
                        paramNames = params.map(function (p) { return p.paramName; });
                        pseudoAsyncBody = "\n      const _asyncCustomFunctionWrapper = async (" + paramNames.join(", ") + ") => {\n        " + functionBody + "\n      };\n      return _asyncCustomFunctionWrapper(" + paramNames.join(", ") + ");\n    ";
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        customFunction = new (Function.bind.apply(Function, __spreadArray(__spreadArray([void 0], paramNames), [pseudoAsyncBody])))();
                        return [4 /*yield*/, customFunction.apply(void 0, params.map(function (p) { return p.paramValue; }))];
                    case 2:
                        result = _c.sent();
                        return [2 /*return*/, { result: result }];
                    case 3:
                        error_1 = _c.sent();
                        return [2 /*return*/, {
                                result: "",
                                error: (error_1 instanceof Error) ? error_1.message : String(error_1),
                            }];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HypClientLibFunctionExecManager.prototype.runCustomFunctionFromUrl = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var script;
            return __generator(this, function (_a) {
                // Note: maybe cache the downloaded scripts?
                // Keep them in the added <script>?
                // Maybe have a map or URL to <script>?
                // Would there be a mecahnism to reload them?
                // TODO!!!
                throw new Error("functionExecMgr.runCustomFunctionFromUrl() not implemented");
            });
        });
    };
    return HypClientLibFunctionExecManager;
}());
exports.HypClientLibFunctionExecManager = HypClientLibFunctionExecManager;
