"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypClientLibApi = void 0;
var client_api_1 = require("../shared/client-api");
var hyp_client_lib_1 = require("./hyp-client-lib");
var function_exec_manager_1 = require("./managers/function-exec-manager");
var DEFAULT_SANDBOX_URL = "https://www.hypertable.net";
var LOCAL_SANDBOX_URL = "http://localhost:3000";
/**
 * The client library end of the message passing mechanism between the client library and Hypertable.
 *
 * To consume the client library use HypClientLib.
 */
var HypClientLibApi = /** @class */ (function () {
    // public get Modals() { return HypClientLibModalManager; }
    // public get Utils() { return HypClientLibUtils; }
    function HypClientLibApi(options) {
        var _this = this;
        if (options.targetOrigin === "default")
            options.targetOrigin = DEFAULT_SANDBOX_URL;
        if (options.targetOrigin === "local")
            options.targetOrigin = LOCAL_SANDBOX_URL;
        if (!options.targetOrigin || options.targetOrigin === "" || options.targetOrigin === "*") {
            throw new Error("Target origin " + options.targetOrigin + " is not allowed");
        }
        this.apiTransport = new client_api_1.ClientLibApiTransport(window.parent, options.targetOrigin);
        this.apiTransport.logging = true;
        this.apiTransport.listen();
        this.clientLib = new hyp_client_lib_1.HypClientLib(this);
        this.funcExecMgr = new function_exec_manager_1.HypClientLibFunctionExecManager(this.clientLib, new hyp_client_lib_1.TestModeHypClientLib(this));
        this.apiTransport.onMessage(function (msgType, params, messageId) { return __awaiter(_this, void 0, void 0, function () {
            var memberFn;
            var _a;
            return __generator(this, function (_b) {
                memberFn = this[msgType];
                if (memberFn !== undefined)
                    return [2 /*return*/, (_a = memberFn).call.apply(_a, __spreadArray([this], params))]; // (memberFn as any)(...params);
                // if (msgType === "isInitialised") return this.isInitialised();
                // if (msgType === "runFieldFunction") return this.runFieldFunction(params[0], params[1]);
                return [2 /*return*/, undefined];
            });
        }); });
        this.notifyInitialised();
    }
    HypClientLibApi.prototype.isInitialised = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport !== undefined];
            });
        });
    };
    HypClientLibApi.prototype.notifyInitialised = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.apiTransport.send("notifyInitialised", []);
                return [2 /*return*/];
            });
        });
    };
    HypClientLibApi.prototype.runFieldFormula = function (functionBody, record) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.funcExecMgr.runFieldFormula(functionBody, record)];
            });
        });
    };
    HypClientLibApi.prototype.runFieldFormulaMany = function (functionBody, records) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.funcExecMgr.runFieldFormulaMany(functionBody, records)];
            });
        });
    };
    HypClientLibApi.prototype.runCustomFunction = function (functionBody, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.funcExecMgr.runCustomFunction(functionBody, opts)];
            });
        });
    };
    HypClientLibApi.prototype.runCustomFunctionFromUrl = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.funcExecMgr.runCustomFunctionFromUrl(url)];
            });
        });
    };
    HypClientLibApi.prototype.callCustomFunction = function (functionId, args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error("Not implemented");
            });
        });
    };
    HypClientLibApi.prototype.logMessage = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.apiTransport.send("logMessage", [message, opts]);
                return [2 /*return*/];
            });
        });
    };
    HypClientLibApi.prototype.alertNotify = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("alertNotify", [message, opts]).catch(function (err) {
                        return;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.alertConfirm = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("alertConfirm", [message, opts]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.alertPrompt = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("alertPrompt", [message, opts]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.alertToast = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("alertToast", [message]).catch(function (err) {
                        return;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.showCustomModal = function (customViewId, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("showCustomModal", [customViewId, opts]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.openView = function (viewId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("openView", [viewId, recordId]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.findCollection = function (collectionNameOrId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("findCollection", [collectionNameOrId]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.findRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("findRecord", [collectionId, recordId]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.findRecords = function (collectionId, recordIds) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("findRecords", [collectionId, recordIds]).catch(function (err) {
                        return [];
                    })];
            });
        });
    };
    HypClientLibApi.prototype.queryRecords = function (collectionId, query) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("queryRecords", [collectionId, query]).catch(function (err) {
                        return [];
                    })];
            });
        });
    };
    HypClientLibApi.prototype.getAllRecords = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("getAllRecords", [collectionId]).catch(function (err) {
                        return [];
                    })];
            });
        });
    };
    HypClientLibApi.prototype.createRecord = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("createRecord", [collectionId]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.updateRecord = function (collectionId, recordId, patch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("updateRecord", [collectionId, recordId, patch]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.deleteRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("deleteRecord", [collectionId, recordId]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.cloneRecord = function (collectionId, recordId, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("cloneRecord", [collectionId, recordId, opts]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.selectRecord = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("selectRecord", [collectionId]).catch(function (err) {
                        return undefined;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.selectRecords = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("selectRecords", [collectionId]).catch(function (err) {
                        return [];
                    })];
            });
        });
    };
    HypClientLibApi.prototype.saveBatch = function (batch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("saveBatch", [batch]).catch(function (err) {
                        return false;
                    })];
            });
        });
    };
    HypClientLibApi.prototype.printMarkdown = function (markdown, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("printMarkdown", [markdown, options]).catch(function (err) {
                        return [];
                    })];
            });
        });
    };
    HypClientLibApi.prototype.openRecordPreview = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.apiTransport.sendWithResponse("openRecordPreview", [collectionId, recordId])];
            });
        });
    };
    // /**
    //  * Get the collection definition with the given ID.
    //  * Throws an error if the collection is not found
    //  */
    // public getCollection(id: string): Promise<HypCollection> {
    //   throw "Todo";
    // }
    // /**
    //  * Find the collection with the given ID, returns undefined if not found
    //  */
    // public findCollection(id: string): Promise<HypCollection | undefined> {
    //   throw "Todo";
    // }
    /**
     * Open the specified record.
     * Optionally specify the detail view
     */
    HypClientLibApi.prototype.openRecord = function (record, view) {
        throw new Error("TODO");
    };
    /**
     * Open the default view of the specified collection
     */
    HypClientLibApi.prototype.openCollection = function (collection, view) {
        throw new Error("TODO");
    };
    /** Noop */
    HypClientLibApi.prototype.noop = function () { };
    return HypClientLibApi;
}());
exports.HypClientLibApi = HypClientLibApi;
