"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypClientLibModalManager = void 0;
/**
 *
 *
 * @internal
 */
var HypClientLibModalManager = /** @class */ (function () {
    function HypClientLibModalManager() {
    }
    // public static alertNotify(): Promise<void> {
    //   throw new Error("TODO");
    // }
    // public static alertConfirm(): Promise<void> {
    //   throw new Error("TODO");
    // }
    // public static alertPrompt(): Promise<void> {
    //   throw new Error("TODO");
    // }
    HypClientLibModalManager.prototype.alertNotify = function (message, opts) {
        throw new Error("Not implemented");
    };
    HypClientLibModalManager.prototype.alertConfirm = function (message, opts) {
        throw new Error("Not implemented");
    };
    HypClientLibModalManager.prototype.alertPrompt = function (message, opts) {
        throw new Error("Not implemented");
    };
    HypClientLibModalManager.prototype.alertToast = function (message) {
        throw new Error("Not implemented");
    };
    HypClientLibModalManager.prototype.showCustomModal = function (customViewId, opts) {
        throw new Error("Not implemented");
    };
    return HypClientLibModalManager;
}());
exports.HypClientLibModalManager = HypClientLibModalManager;
