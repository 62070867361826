"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientLibApiTransport = void 0;
/**
 * Internal interface implemented by both ends of the RPC client library.
 */
var ClientLibApiTransport = /** @class */ (function () {
    function ClientLibApiTransport(targetWindow, targetOrigin) {
        var _this = this;
        this.routerFn = undefined;
        /**
         * Map of ongoing response listener keys to the associated handler function.
         *
         * Each key is built using the message's ID and procedure name: msg.proc + msg.id
         *
         * If the response listener times out, the entry will be automatically removed from this map.
         */
        this.ongoingResponseListeners = new Map();
        this.defaultResponseTimeout = 5000;
        this.logging = false;
        this.messageListener = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var message;
            return __generator(this, function (_a) {
                // this.log(`Message received from ${event.origin} (${JSON.stringify(event.data).length > 50
                //   ? JSON.stringify(event.data).slice(0, 50) + "..."
                //   : JSON.stringify(event.data)})`);
                if (this.logging && !event.data.vscodeSetImmediateId) {
                    // this.log("sliced: " + String(event.data).slice(0, 50));
                    this.log("Message received from " + event.origin, event.data);
                }
                if (!this.targetOrigin || event.origin !== this.targetOrigin) {
                    return [2 /*return*/, this.log("Message origin does not match target (" + event.origin + " != " + this.targetOrigin + ")")];
                }
                message = event.data;
                if (!message.id) {
                    return [2 /*return*/, this.log("Received message has no ID")];
                }
                if (!message.type) {
                    return [2 /*return*/, this.log("Received message has no type")];
                }
                if (!message.proc) {
                    return [2 /*return*/, this.log("Received message has procedure name")];
                }
                if (message.type === "message")
                    this.handleMessage(message);
                if (message.type === "response")
                    this.handleResponse(message);
                return [2 /*return*/];
            });
        }); };
        if (!targetWindow) {
            throw new Error("No target window is supplied");
        }
        this.targetWindow = targetWindow;
        if (!targetOrigin || targetOrigin === "" || targetOrigin === "*") {
            throw new Error("The specified target origin \"" + targetOrigin + "\" is not allowed");
        }
        if (targetOrigin.startsWith("http://")) {
            console.error("The specified target origin is not secure");
        }
        this._targetOrigin = targetOrigin;
        // this.log
    }
    Object.defineProperty(ClientLibApiTransport.prototype, "targetOrigin", {
        get: function () { return this._targetOrigin; },
        enumerable: false,
        configurable: true
    });
    /**
     * Message IDs only need to distinguish between messages of the same type that are
     * waiting for a response at the same time. There will not be many of these at once.
     */
    ClientLibApiTransport.prototype.newMessageId = function () {
        return (Math.random() + 1).toString().replace(".", "");
    };
    ClientLibApiTransport.prototype.log = function () {
        var message = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            message[_i] = arguments[_i];
        }
        this.logging && console.log.apply(console, __spreadArray(["[ClientLibApiTransport]"], message));
    };
    ClientLibApiTransport.prototype.handleMessage = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var id, type, proc, params, responseValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = message.id, type = message.type, proc = message.proc, params = message.params;
                        if (!params) {
                            return [2 /*return*/, this.log("Received message has no params list")];
                        }
                        if (!this.routerFn) {
                            return [2 /*return*/, this.log("No router function to route received message")];
                        }
                        return [4 /*yield*/, this.routerFn(proc, params, id)
                                .then(function (result) { return { result: result, error: undefined }; })
                                .catch(function (error) { return { result: undefined, error: error }; })];
                    case 1:
                        responseValue = _a.sent();
                        // If a value was returned or an error was thrown, send a response to the received message
                        if (responseValue.result !== undefined || responseValue.error !== undefined) {
                            this.respond(id, proc, responseValue);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientLibApiTransport.prototype.handleResponse = function (response) {
        var _a;
        var id = response.id, proc = response.proc, result = response.result, type = response.type, error = response.error;
        var listenerKey = proc + id;
        (_a = this.ongoingResponseListeners.get(listenerKey)) === null || _a === void 0 ? void 0 : _a({ result: result, error: error });
    };
    /** Start listening for messages sent to this end of the client library API */
    ClientLibApiTransport.prototype.listen = function () {
        window.addEventListener("message", this.messageListener, false);
        this.log("Listening for messages to this window");
    };
    /** Teardown any listeners and open connections */
    ClientLibApiTransport.prototype.destroy = function () {
        window.removeEventListener("message", this.messageListener);
        this.routerFn = undefined;
        this.log("Destroyed");
    };
    /** Define the function to call when incoming messages are received */
    ClientLibApiTransport.prototype.onMessage = function (routerFn) {
        this.routerFn = routerFn;
        this.log("Route function set");
    };
    /**
     * Send a message across the client lib API and do not listen for a response
     *
     * N.B. Parameters are not typed! Ensure they are in the correct order!
     */
    ClientLibApiTransport.prototype.send = function (proc, params) {
        var id = this.newMessageId();
        var message = { id: id, type: "message", proc: proc, params: params };
        this.log("Sending message \"" + proc + "\" to " + this.targetOrigin);
        this.targetWindow.postMessage(message, this.targetOrigin);
    };
    /**
     * Send a message across the client lib API and set up a listener for a response.
     * Once a response to the sent message has been received, it is returned asynchronously.
     *
     * If an error is thrown at the other end, or if an error occurs while sending the message,
     * it is thrown from this method instead of returning a response.
     *
     * N.B. Parameters are not typed! Ensure they are in the correct order!
     */
    ClientLibApiTransport.prototype.sendWithResponse = function (proc, params) {
        var _this = this;
        return new Promise(function (res, rej) {
            var id = _this.newMessageId();
            var listenerKey = proc + id;
            // Send the initial message
            var message = { id: id, type: "message", proc: proc, params: params };
            _this.log("Sending message \"" + proc + "\" to " + _this.targetOrigin + ", awaiting response...");
            _this.targetWindow.postMessage(message, _this.targetOrigin);
            // Reject the response after timeout
            var timeout = setTimeout(function () {
                _this.ongoingResponseListeners.delete(listenerKey);
                _this.log("Timeout while waiting for response");
                rej(new Error("Timeout waiting for response (" + _this.defaultResponseTimeout + "ms)"));
            }, _this.defaultResponseTimeout);
            // Add the response listener
            _this.ongoingResponseListeners.set(listenerKey, function (responseValue) {
                // Clean up
                _this.ongoingResponseListeners.delete(listenerKey);
                clearTimeout(timeout);
                _this.log("Response received");
                // Return the result or throw an error
                var result = responseValue.result, error = responseValue.error;
                if (error) {
                    rej(error);
                }
                else {
                    res(result);
                }
            });
        });
    };
    /**
     * Send a response message to respond to a received API message.
     *
     * Responses are sent automatically based on the return type of the RPC API interface method.
     */
    ClientLibApiTransport.prototype.respond = function (id, proc, value) {
        var response = {
            id: id,
            type: "response",
            proc: proc,
            result: value.result,
            error: value.error,
        };
        this.log("Sending response to \"" + proc + "\":", value.result);
        this.targetWindow.postMessage(response, this.targetOrigin);
    };
    return ClientLibApiTransport;
}());
exports.ClientLibApiTransport = ClientLibApiTransport;
