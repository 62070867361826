"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypBatch = void 0;
/**
 * A batch applies a number of record edits at once, rolling tham all back if any fail.
 */
var HypBatch = /** @class */ (function () {
    /** @internal */
    function HypBatch(lib) {
        /** @internal */
        this.steps = [];
        this.lib = lib;
    }
    /**
     * Read the batch as an object fit for transmission over the client lib API
     * @internal
     */
    HypBatch.prototype.asApiBatch = function () {
        var batch = {
            steps: [],
        };
        this.steps.forEach(function (step) {
            var libStep = {
                op: step.op,
                record: {
                    id: step.record.id,
                    collectionId: step.record.collectionId,
                    createdAt: step.record.createdAt,
                    createdBy: step.record.createdBy,
                    updatedAt: step.record.updatedAt,
                    updatedBy: step.record.updatedBy,
                    fieldValues: step.record.fieldValues,
                    title: step.record.title,
                }
            };
            batch.steps.push(libStep);
        });
        return batch;
    };
    /**
     * Create a new record in the given collection. The returned record can be edited before
     * executing the batch without needing to be passed to `updateRecord(...)`
     * @param collection
     * @returns
     */
    HypBatch.prototype.createRecord = function (collection) {
        var record = this.lib.createRecordWithoutSaving(collection);
        this.steps.push({
            op: "insert",
            record: record,
        });
        return record;
    };
    /**
     * Update the field values of the given record as part of this batch
     * @param record
     */
    HypBatch.prototype.updateRecord = function (record) {
        this.steps.push({
            op: "update",
            record: record,
        });
    };
    /**
     * Update the field values of the all given records as part of this batch
     * @param records
     */
    HypBatch.prototype.updateRecords = function (records) {
        var _this = this;
        records.forEach(function (record) {
            _this.updateRecord(record);
        });
    };
    /**
     * Delete the given record as part of this batch
     * @param record
     */
    HypBatch.prototype.deleteRecord = function (record) {
        this.steps.push({
            op: "delete",
            record: record,
        });
    };
    /**
     * Delete all the given records as part of this batch
     * @param records
     */
    HypBatch.prototype.deleteRecords = function (records) {
        var _this = this;
        records.forEach(function (record) {
            _this.deleteRecord(record);
        });
    };
    /**
     * Perform all the edits added to the batch in one operation
     * @returns
     */
    HypBatch.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lib.saveBatch(this)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return HypBatch;
}());
exports.HypBatch = HypBatch;
