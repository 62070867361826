"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HypRecord = void 0;
var utils_1 = require("../utils/utils");
/**
 * A Hypertable record available to consumers of the client library.
 *
 * Method calls make requests across the client library sandbox boundary.
 */
var HypRecord = /** @class */ (function () {
    /** @internal */
    function HypRecord(lib, initial) {
        this.lib = lib;
        this.id = initial.id;
        this.collectionId = initial.collectionId;
        this.createdAt = initial.createdAt;
        this.createdBy = initial.createdBy;
        this.updatedAt = initial.updatedAt;
        this.updatedBy = initial.updatedBy;
        this.fieldValues = utils_1.HypClientLibUtils.dereference(initial.fieldValues);
        this.title = initial.title;
    }
    /** The createdAt timestamp as a Date object */
    HypRecord.prototype.createdAtDate = function () { return new Date(this.createdAt); };
    /** The updatedAt timestamp as a Date object */
    HypRecord.prototype.updatedAtDate = function () { return new Date(this.updatedAt); };
    /** More information about the user who created the record */
    HypRecord.prototype.createdByUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, {
                        id: "TODO",
                        name: "TODO",
                        colour: "TODO",
                        image: "TODO",
                    }];
            });
        });
    };
    /** More information about the user who created the record */
    HypRecord.prototype.updatedByUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, {
                        id: "TODO",
                        name: "TODO",
                        colour: "TODO",
                        image: "TODO",
                    }];
            });
        });
    };
    /**
     * Read the value of the specified field, either specifiying the field's ID, ~~the field's key~~
     * or using an object received from a HypCollection.
     * @param field The ID of the field, or a HypField object
     * @returns The value of the specified field
     * @throws An error if the specified field was not found
     */
    HypRecord.prototype.readField = function (field) {
        var fieldId = typeof field === "string" ? field : field.id;
        var value = this.fieldValues[fieldId];
        // if (value === undefined) throw new Error(`Could not read value of field with ID ${fieldId}`);
        return value;
    };
    /**
     * Read the value of the field with the given `key`
     * @param fieldKey The `key` of the field to read
     * @returns The value of the field, may be undefined if the field was not found
     */
    HypRecord.prototype.readFieldByKey = function (fieldKey) {
        var field = this.lib.findFieldFromKey(this.collectionId, fieldKey);
        if (!field)
            return undefined;
        return this.fieldValues[field.id];
    };
    /**
     * Use `readField` instead
     *
     * Read the value of the specified field, either specifiying the field's ID
     * or using an object received from a HypCollection.
     * @param field The ID of the target field, or a HypField object
     * @returns The value of the specified field, or undefined if the field was not found
     * @deprecated
     */
    HypRecord.prototype.readFieldOpt = function (field) {
        var fieldId = typeof field === "string" ? field : field.id;
        var value = this.fieldValues[fieldId];
        return value;
    };
    /**
     * Set the value of the specified field
     * @param field The target field either as an ID or a HypField from a HypCollection
     * @param value The new value as a string
     */
    HypRecord.prototype.setField = function (field, value) {
        var fieldId = typeof field === "string" ? field : field.id;
        this.fieldValues[fieldId] = value;
    };
    /**
     * Set the value of the field with the given `key`
     * @param fieldKey The `key` of the field to update
     * @param value The new value as a string
     * @returns true if the value was set, false if the field was not found
     */
    HypRecord.prototype.setFieldByKey = function (fieldKey, value) {
        var field = this.lib.findFieldFromKey(this.collectionId, fieldKey);
        if (!field)
            return false;
        this.setField(field, value);
        return true;
    };
    /**
     * Save all edits to this record to the database
     */
    HypRecord.prototype.save = function () {
        return this.lib.updateRecord(this.collectionId, this.id, this.fieldValues);
    };
    /**
     * Reload this record from the database, undoing any edits
     */
    HypRecord.prototype.reload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var record, fieldId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lib.findRecord(this.collectionId, this.id)];
                    case 1:
                        record = _a.sent();
                        if (!record)
                            return [2 /*return*/, false];
                        for (fieldId in record.fieldValues) {
                            this.setField(fieldId, record.fieldValues[fieldId]);
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    /**
     * Delete this record from the database
     */
    HypRecord.prototype.delete = function () {
        return this.lib.deleteRecord(this.collectionId, this.id);
    };
    return HypRecord;
}());
exports.HypRecord = HypRecord;
