"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestModeHypClientLib = exports.TEST_MODE_CLIENT_LIB_WAIT = exports.HypClientLib = void 0;
var hyp_collection_1 = require("./data/hyp-collection");
var hyp_record_1 = require("./data/hyp-record");
var hyp_batch_1 = require("./data/hyp-batch");
var utils_1 = require("./utils/utils");
/**
 * Consumers of the Hypertable Client Library do so using this class.
 */
var HypClientLib = /** @class */ (function () {
    function HypClientLib(api) {
        /**
         * A cache of collection information for all collections and records
         * that have been interacted with.
         * @internal
         */
        this.collectionCache = new Map();
        this.api = api;
    }
    Object.defineProperty(HypClientLib.prototype, "utils", {
        /** Get a reference to the collection of utility functions */
        get: function () { return utils_1.HypClientLibUtils; },
        enumerable: false,
        configurable: true
    });
    /**
     * Display a message to the user in a styled modal.
     *
     * The returned promise resolves once they dismiss the modal.
     */
    HypClientLib.prototype.alertNotify = function (message, opts) {
        return this.api.alertNotify(message, opts);
    };
    /**
     * Display a message to the user in a styled modal, requiring them to either confirm or cancel.
     * @param message The message to display in the modal
     * @param opts Optional options to configure the title and buttons of the modal
     * @returns The returned promise resolves with `true` of the user selects the confirm option.
     */
    HypClientLib.prototype.alertConfirm = function (message, opts) {
        return this.api.alertConfirm(message, opts);
    };
    /**
     * Prompt the user to enter some text in an input, displayed in a styled modal.
     * @param message The message to display in the modal
     * @param opts Optional options to configure the title and buttons of the modal
     * @returns The string the user entered, or undefined if the user cancelled the modal
     */
    HypClientLib.prototype.alertPrompt = function (message, opts) {
        return this.api.alertPrompt(message, opts);
    };
    /**
     * Display a short message to the user, in a non-interrupting toast pop-up (AKA candybar)
     * @param message The message to display
     */
    HypClientLib.prototype.alertToast = function (message) {
        return this.api.alertToast(message);
    };
    // TODO: or a view object?
    HypClientLib.prototype.showCustomModal = function (customViewId, opts) {
        return this.api.showCustomModal(customViewId, opts);
    };
    /**
     * Immediately open the specified View
     * @param viewId The View to open
     * @param recordId Specify a record to select if the View is a Record View
     * @returns Whether the specified View was opened
     */
    HypClientLib.prototype.openView = function (viewId, recordId) {
        return this.api.openView(viewId, recordId);
    };
    /**
     * Open the specified record in the record preview modal
     * @param recordId
     */
    HypClientLib.prototype.openRecordPreview = function (collectionId, recordId) {
        return this.api.openRecordPreview(collectionId, recordId);
    };
    /**
     * Open the specified record in the record preview modal
     * @param record
     */
    HypClientLib.prototype.openPreview = function (record) {
        return this.openRecordPreview(record.collectionId, record.id);
    };
    /**
     * Log a message to the Hypertable custom function log
     * @param message The message string to log
     * @param level The logging level/severity (default: "LOG")
     */
    HypClientLib.prototype.log = function (message, level) {
        this.api.logMessage(message, { level: level || "LOG" });
    };
    /**
     * Get the collection with the given name.
     * If the collection is renamed, this call will need to be updated.
     * @param name The name of the desired collection
     * @returns The specified collection
     * @throws An error if the specified collection as not found
     */
    HypClientLib.prototype.getCollection = function (name) {
        return __awaiter(this, void 0, void 0, function () {
            var coll, collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.findCollection(name)];
                    case 1:
                        coll = _a.sent();
                        if (!coll)
                            throw new Error("Could not find collection with name: \"" + name + "\"");
                        collection = new hyp_collection_1.HypCollection(this, coll);
                        this.collectionCache.set(coll.id, collection);
                        return [2 /*return*/, collection];
                }
            });
        });
    };
    /**
     * Get the collection with the given ID.
     * @param id The ID of the desired collection
     * @returns The specified collection
     * @throws An error if the specified collection is not found
     */
    HypClientLib.prototype.getCollectionById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var coll, collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.collectionCache.has(id))
                            return [2 /*return*/, this.collectionCache.get(id)];
                        return [4 /*yield*/, this.api.findCollection(id)];
                    case 1:
                        coll = _a.sent();
                        if (!coll)
                            throw new Error("Could not find collection with ID: \"" + id + "\"");
                        collection = new hyp_collection_1.HypCollection(this, coll);
                        this.collectionCache.set(coll.id, collection);
                        return [2 /*return*/, collection];
                }
            });
        });
    };
    /** Find the collection with the given ID */
    HypClientLib.prototype.findCollection = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            var coll, collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.collectionCache.has(collectionId))
                            return [2 /*return*/, this.collectionCache.get(collectionId)];
                        return [4 /*yield*/, this.api.findCollection(collectionId)];
                    case 1:
                        coll = _a.sent();
                        if (!coll)
                            return [2 /*return*/, undefined];
                        collection = new hyp_collection_1.HypCollection(this, coll);
                        this.collectionCache.set(coll.id, collection);
                        return [2 /*return*/, collection];
                }
            });
        });
    };
    HypClientLib.prototype.findFieldFromKey = function (collectionId, fieldKey) {
        var _a;
        return (_a = this.collectionCache.get(collectionId)) === null || _a === void 0 ? void 0 : _a.getFieldByKey(fieldKey);
    };
    HypClientLib.prototype.getRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            var rec;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.findRecord(collectionId, recordId)];
                    case 2:
                        rec = _a.sent();
                        if (!rec)
                            throw new Error("Could not find record with ID " + recordId + " in collection with ID " + collectionId);
                        return [2 /*return*/, new hyp_record_1.HypRecord(this, rec)];
                }
            });
        });
    };
    HypClientLib.prototype.getRecords = function (collectionId, recordIds) {
        return __awaiter(this, void 0, void 0, function () {
            var recs;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.findRecords(collectionId, recordIds)];
                    case 2:
                        recs = _a.sent();
                        if (!recs || recs.length !== recordIds.length) {
                            throw new Error("Could not find all " + recordIds.length + " records with given IDs in collection with ID " + collectionId);
                        }
                        return [2 /*return*/, recs.map(function (r) { return new hyp_record_1.HypRecord(_this, r); })];
                }
            });
        });
    };
    HypClientLib.prototype.findRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            var rec;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.findRecord(collectionId, recordId)];
                    case 2:
                        rec = _a.sent();
                        return [2 /*return*/, rec ? new hyp_record_1.HypRecord(this, rec) : undefined];
                }
            });
        });
    };
    HypClientLib.prototype.findRecords = function (collectionId, recordIds) {
        return __awaiter(this, void 0, void 0, function () {
            var recs;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.findRecords(collectionId, recordIds)];
                    case 2:
                        recs = _a.sent();
                        return [2 /*return*/, recs.map(function (r) { return new hyp_record_1.HypRecord(_this, r); })];
                }
            });
        });
    };
    HypClientLib.prototype.queryRecords = function (collectionId, query) {
        return __awaiter(this, void 0, void 0, function () {
            var recs;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.queryRecords(collectionId, query)];
                    case 2:
                        recs = _a.sent();
                        return [2 /*return*/, recs.map(function (r) { return new hyp_record_1.HypRecord(_this, r); })];
                }
            });
        });
    };
    HypClientLib.prototype.getAllRecords = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            var recs;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.getAllRecords(collectionId)];
                    case 2:
                        recs = _a.sent();
                        return [2 /*return*/, recs.map(function (r) { return new hyp_record_1.HypRecord(_this, r); })];
                }
            });
        });
    };
    HypClientLib.prototype.createRecord = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            var rec;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.createRecord(collectionId)];
                    case 2:
                        rec = _a.sent();
                        return [2 /*return*/, rec ? new hyp_record_1.HypRecord(this, rec) : undefined];
                }
            });
        });
    };
    HypClientLib.prototype.createRecordWithoutSaving = function (collection) {
        if (!collection)
            throw new Error("Cannot create Record without Collection");
        var rec = {
            id: this.utils.id(),
            collectionId: collection.id,
            createdAt: "",
            createdBy: "",
            updatedAt: "",
            updatedBy: "",
            fieldValues: {},
            title: "",
        };
        collection.fields.forEach(function (f) {
            rec.fieldValues[f.id] = "";
        });
        return new hyp_record_1.HypRecord(this, rec);
    };
    HypClientLib.prototype.updateRecord = function (collectionId, recordId, patch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.api.updateRecord(collectionId, recordId, patch)];
                }
            });
        });
    };
    HypClientLib.prototype.deleteRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.api.deleteRecord(collectionId, recordId)];
                }
            });
        });
    };
    HypClientLib.prototype.cloneRecord = function (collectionId, recordId, opts) {
        return __awaiter(this, void 0, void 0, function () {
            var record;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.cloneRecord(collectionId, recordId, opts)];
                    case 2:
                        record = _a.sent();
                        return [2 /*return*/, record ? new hyp_record_1.HypRecord(this, record) : undefined];
                }
            });
        });
    };
    HypClientLib.prototype.selectRecord = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            var record;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.selectRecord(collectionId)];
                    case 2:
                        record = _a.sent();
                        return [2 /*return*/, record ? new hyp_record_1.HypRecord(this, record) : undefined];
                }
            });
        });
    };
    HypClientLib.prototype.selectRecords = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            var records;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollectionById(collectionId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.api.selectRecords(collectionId)];
                    case 2:
                        records = _a.sent();
                        return [2 /*return*/, records ? records.map(function (r) { return new hyp_record_1.HypRecord(_this, r); }) : undefined];
                }
            });
        });
    };
    HypClientLib.prototype.createBatch = function () {
        return new hyp_batch_1.HypBatch(this);
    };
    HypClientLib.prototype.saveBatch = function (batch) {
        return __awaiter(this, void 0, void 0, function () {
            var apiBatch;
            return __generator(this, function (_a) {
                apiBatch = batch.asApiBatch();
                return [2 /*return*/, this.api.saveBatch(apiBatch)];
            });
        });
    };
    HypClientLib.prototype.printMarkdown = function (markdown, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.printMarkdown(markdown, options)];
            });
        });
    };
    return HypClientLib;
}());
exports.HypClientLib = HypClientLib;
/**
 * The duration to wait while mocking async client lib function calls
 */
exports.TEST_MODE_CLIENT_LIB_WAIT = 250;
var TestModeHypClientLib = /** @class */ (function (_super) {
    __extends(TestModeHypClientLib, _super);
    function TestModeHypClientLib(api) {
        return _super.call(this, api) || this;
    }
    TestModeHypClientLib.prototype.logTestCall = function (methodName) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // ${TEST_MODE_WAIT}ms
                        this.api.logMessage("awaiting mock \"" + methodName + "(...)\" call...", { level: "TEST" });
                        return [4 /*yield*/, this.utils.wait(exports.TEST_MODE_CLIENT_LIB_WAIT)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.alertNotify = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("alertNotify")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.alertConfirm = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("alertConfirm")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.alertPrompt = function (message, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("alertPrompt")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.alertToast = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("alertToast")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.showCustomModal = function (customViewId, opts) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("showCustomModal")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.log = function (message, level) {
        this.api.logMessage(message, { level: level || "LOG" });
    };
    TestModeHypClientLib.prototype.getCollection = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("getCollection")];
                    case 1:
                        _a.sent();
                        throw new Error("getCollection(...) always fails in test mode");
                }
            });
        });
    };
    TestModeHypClientLib.prototype.findCollection = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("findCollection")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.getRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("getRecord")];
                    case 1:
                        _a.sent();
                        throw new Error("getRecord(...) always fails in test mode");
                }
            });
        });
    };
    TestModeHypClientLib.prototype.findRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("findRecord")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.queryRecords = function (collectionId, query) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("findRecords")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, []];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.createRecord = function (collectionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("createRecord")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.updateRecord = function (collectionId, recordId, updates) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("updateRecord")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    TestModeHypClientLib.prototype.deleteRecord = function (collectionId, recordId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.logTestCall("deleteRecord")];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    return TestModeHypClientLib;
}(HypClientLib));
exports.TestModeHypClientLib = TestModeHypClientLib;
